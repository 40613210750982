export default function getCDNImage(path) {
  if (typeof path !== 'string') return path;

  if (path.includes('/wp-content/')) {
    return `${process.env.REACT_APP_CDN_URL}/${path.split('/wp-content/')[1]}`;
  }

  if (path.startsWith('http://')) {
    return path.replace('http:', 'https:'); // Force HTTPS or fail since mixed content is not authorized
  }

  if (path.startsWith('//')) {
    return path.replace('//', 'https://');
  }

  if (path.startsWith('/')) {
    return process.env.REACT_APP_CDN_URL + path;
  }

  return path;
}
